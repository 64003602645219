<template>
    <div class="main">
        <div class="step1" v-if="curStep === 'step1'">
            <div class="title">申报须知</div>
            <div class="cont">
                <p class="cont-tit">一、教育理念</p>
                <p class="cont-paragraph">幼儿园需全面贯彻落实全国教育大会精神，牢固树立健康第一的教育理念，严格按照《全国足球特色幼儿园游戏活动指南》要求，积极开 展幼儿足球相关工 作，帮助幼儿在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。</p>
                <p class="cont-tit">二、教育要求</p>
                <p class="cont-paragraph">入选幼儿园需严格按照全国校足办和中国足球发展基金会要求，严格按照全国足球特色幼儿园系统平台课程所提供的教学体系要求，全面 开展相关教学活动， 包括但不限于:幼儿园基础信息管理体系，幼儿足球游戏课程体系，幼儿足球游戏测试体系，幼儿足球运动评估体系，幼儿 足球教师资培训体系，小小运动会， 幼儿足球全国综合量化评估体系等;按时上传相关数据材料，并确保真实有效。</p>
                <p class="cont-tit">三、考核要求</p>
                <p class="cont-paragraph">入选幼儿园将接受由中国足球发展基金会提供的 2 万元定向资助金。此资助金是为示范园开展试点教学活动的费用，实行专款专用。</p>
                <p class="cont-tit">四、资格要求</p>
                <p class="cont-demand" :class="(certificationStatus == 0 || certificationStatus == 2) ? 'cont-undemand' : ''" @click="goCertification">1.{{certificationList[certificationStatus]}}</p>
                <p class="cont-demand" :class="{'cont-undemand noPointer':!isWhiteList }">2.{{isWhiteList ? '园所不在禁止申报名单中。' : '园所不具备申报资格。'}}</p>
            </div>
            <div class="start-declare">
                <div class="declare-check" @click="checkStatus = !checkStatus"><span :class="checkStatus ? 'active' : ''" ></span> 已知悉以上条件，自愿申报 </div>
                <div :class="isWhiteList && (certificationStatus==88 || certificationStatus==1) && checkStatus ? 'start-declare-btn' : 'undeclare'" @click="startDeclare">开始申报</div>
            </div>
        </div>
        <div class="step2" v-if="curStep === 'step2'">
            <div class="title">申报填写</div>
            <div class="form">
                <p class="form-tit">1.请填写以下内容</p>
                <el-row :gutter="40" class="form-row">
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>园所名称:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.spSchoolName" placeholder="请输入园所名称"></el-input></p>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>法定代表人:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.spContacter" placeholder="请输入法定代表人"></el-input></p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="40" class="form-row">
                    <el-col>
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>园所地址:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.paddress" placeholder="请输入园所地址"></el-input></p>
                            <p class="form-item-tips">此园所地址需保证此次及今后收发快递畅通</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="40" class="form-row">
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>开户单位名称:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.companyName" placeholder="请输入开户单位名称"></el-input></p>
                            <p class="form-item-tips">开户单位名称用于开具发票与汇款</p>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>法人身份证:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.idCard" placeholder="请输入法人身份证"></el-input></p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="40" class="form-row">
                    <el-col>
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>银行账号:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.bankAccount" placeholder="请输入银行账号"></el-input></p>
                            <p class="form-item-tips">该收/付款账号为同一账号，主要用于园所接收资助金资助、以及全国足球特色幼儿园系统平台定向打款使用</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="40" class="form-row">
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>开户行:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.bankName" placeholder="请输入开户行"></el-input></p>
                            <p class="form-item-tips">请输入年开户行的名称(支行信息页需要填写)，例如北京招商银行望京支行</p>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>统一社会信用代码:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.creditCode" placeholder="请输入统一社会信用代码"></el-input></p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="40" class="form-row">
                    <el-col :span="12">
                        <div class="form-item">
                            <p class="form-item-tit"><span>*</span>邮箱:</p>
                            <p class="form-item-inp"><el-input v-model="schoolInfo.spEmail" placeholder="请输入邮箱"></el-input></p>
                            <input
                                style="display:block;width:0px;height:0px;opacity:0;"
                                placeholder=""
                                resize="none"
                            />
                        </div>
                    </el-col>
                </el-row>
                <p class="form-tit mt30">2.园所需开具发票等有效票据，或当地教育局/财政局等单位可为其代收代付代开票</p>
                <div class="invoice-list">
                    <el-row :gutter="20" class="form-row">
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '普票'}" @click="handleInvoiceType(1,'普票')">1 增值税普通发票</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '专票'}" @click="handleInvoiceType(2,'专票')">2 增值税专用发票</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '1、公益事业接收捐赠统一收据'}" @click="handleInvoiceType(3,'1、公益事业接收捐赠统一收据')">3 公益事业接收捐赠统一收据</div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" class="form-row">
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '2、公益事业捐赠统一票据（电子）'}" @click="handleInvoiceType(3, '2、公益事业捐赠统一票据（电子）')">4 公益事业捐赠统一票据(电子)</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '3、通用机打发票'}" @click="handleInvoiceType(3, '3、通用机打发票')">5 通用机打发票</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '4、行政事业单位资金往来结算票据'}" @click="handleInvoiceType(3, '4、行政事业单位资金往来结算票据')">6 行政事业单位资金往来结算票据</div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" class="form-row">
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '5、专用收款收据'}" @click="handleInvoiceType(3, '5、专用收款收据')">7 专用收款收据</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '6、行政事业单位非经营服务性收入收款收据'}" @click="handleInvoiceType(3, '6、行政事业单位非经营服务性收入收款收据')">8 行政事业单位非经营服务性收入收款收据</div>
                        </el-col>
                        <el-col :span="8">
                            <div class="invoice-item" :class="{active: curInvoiceVal === '7、其他票据'}" @click="handleInvoiceType(3, '7、其他票据')">9 其他票据</div>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <p class="reminder"><span>*</span>温馨提示：请上传相关票据图片</p>
                    <el-upload
                    class="avatar-uploader"
                    :headers="{ token: $store.state.userInfo.token }"
                    :action="constant.UPLOAD_URL + '/file/upload'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="step-btn">
                    <div class="step-btn-pre" @click="declarePre">上一步</div>
                    <div class="step-btn-next" @click="nextConfirm">下一步</div>
                </div>
            </div>
        </div>
        <div class="step3" v-if="curStep === 'step3'">
            <div class="title">申报确认</div>
            <div>
                <div class="doc-file">
                    <p class="doc-file-l" @click="curStep = 'step32'">示范园项目资助协议书</p>
                    <p class="doc-file-r" @click="curStep = 'step31'">定向服务购买协议</p>
                </div>
                <div class="declare-check" @click="confirmCheckStatus = !confirmCheckStatus"><span :class="{active : confirmCheckStatus}"></span> 我已阅读《示范园项目资助协议书》及《定向服务购买协议》，清楚申报流程，自愿参加全国足球特色幼儿园示范园（试点）项目 </div>
                <div class="declare-tip">此版本为协议模板，如与最终协议存在细微出入，请以最终签约版本为准</div>
            </div>
            <div class="step-btn mt40">
                <div class="step-btn-pre" @click="confirmPre">上一步</div>
                <div :class="confirmCheckStatus ? 'step-btn-next' : 'unConfirm'" @click="confirmDeclare">确认</div>
            </div>
        </div>
        <div class="step4" v-if="curStep === 'step4'">
            <div class="title">申报状态</div>
            <div class="step4-cont">
                <img :src="declareImg" alt="" class="step4-cont-img">
                <p class="step4-cont-status">{{declareStatus}}</p>
                <p class="step4-cont-tip" v-html="declareCont"></p>
            </div>
        </div>
        <!-- 定向服务购买协议 -->
        <div class="step6" v-show="curStep === 'step31'">
        <div class="title">《定向服务购买协议》</div>
        <div class="protocol w1200">
            <div class="box">
            <!-- 协议开始 -->
            <p class="18" style="text-align: right">合同编号：</p>
            <p class="18">&nbsp;</p>
            <p class="18">&nbsp;</p>
            <p class="18">&nbsp;</p>
            <p class="18" style="text-align: center">
                <img width="605" height="152" src="../../../assets/img/htc.png" />
            </p>

            <p class="MsoNormal" align="center" style="text-align: center">
                <b>&nbsp;</b>
            </p>
            <p class="MsoNormal" align="center" style="text-align: center">
                <b>&nbsp;</b>
            </p>
            <p
                class="MsoNormal"
                align="center"
                style="text-align: center; font-size: 0.24rem"
            >
                <b>定向服务购买协议</b>
            </p>
            <p class="MsoNormal" align="center" style="text-align: center">
                <b>&nbsp;</b>
            </p>
            <p class="MsoNormal" align="center" style="text-align: center">
                <b>&nbsp;</b>
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>甲方</b><span>：</span><a name="_Hlk43558084"></a
                ><u>小牛体育文化发展（重庆）有限公司</u>
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>乙方</b>：<u
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                ><u
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                ><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>&nbsp;&nbsp;&nbsp;&nbsp;<b
                >幼儿园</b
                ><b>备案号</b><b>:</b
                ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b
                ><b
                ><u
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                ></b
                >
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                甲、乙双方为友好合作，经协商一致，按照国家法律、法规及相关行业政策，签订本协议，以兹共守。
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b><span>一、</span> <span>服务内容与方式</span></b
                ><b></b>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                1.&nbsp;<span>甲方通过</span>“全国足球特色幼儿园系统平台”（以下简称“系统平台”，包括但不限于网站、微信公众号、微信小程序、微信插件及其他方式等）向乙方提供“<a
                name="_Hlk43557838"
                ></a
                ><span>全国足球特色幼儿园线上</span
                ><span>视频游戏教学课程</span>”——“Let’s Get
                Active线上课程”（以下简称“LGA线上课程”），具体服务范围及内容根据乙方选购课程确定。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                2.&nbsp;甲方通过第<u>&nbsp;&nbsp;&nbsp;&nbsp;</u><u>1</u
                ><u>&nbsp;&nbsp;&nbsp;&nbsp;</u>&nbsp;<span
                >种平台向乙方提供服务：</span
                >
            </p>
            <p class="MsoNormal" style="margin-left: 64.2pt; text-indent: -36pt">
                （1）&nbsp;网站（网址：<a href="http://www.prefootball.cn"
                ><u>www.prefootball.cn</u></a
                >）；
            </p>
            <p class="MsoNormal" style="margin-left: 64.2pt; text-indent: -36pt">
                （2）&nbsp;微信公众号；
            </p>
            <p class="MsoNormal" style="margin-left: 64.2pt; text-indent: -36pt">
                （3）&nbsp;其他方式：<u
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                ><u></u>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                3.&nbsp;根据乙方要求，甲方向乙方提供以下服务。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>1）幼儿园基础信息管理体系（KFI） Kindergarten
                Foundational Information；
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>2）幼儿足球游戏课程体系（LGA）Let's Get Active；
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                （3）<span>幼儿足球游戏测试体系（</span>PDA）Physical Data Archives
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>4）<span>幼儿足球运动评估体系</span>(PES) Physical
                Evaluation System
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span
                >5）幼儿园<span>全国综合量化评估体系</span>(KQA）Kindergarten
                Quantitative Assessment
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>6）幼儿足球教师培训<span>体系</span>(TCP)Teachers
                Certification of Prefootball
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>7）<span>小小运动会</span>(KSFA)&nbsp;Kids Sports for
                All
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                4.&nbsp;为保障甲方整体用户需求，为广大用户提供更好服务，甲方有权修改服务方式、优化服务版本、升级服务系统；为提高甲方服务水平，甲方有权随时丰富服务内容、范围，优化服务方式，并有权调整系统平台及服务的名称、规格和功能等。
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <a name="page2"></a><b>二、合同价款与服务期限</b><b></b>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                1.&nbsp;<span>乙方协议代码（即其统一社会信用代码及其法定代表人</span>/授权代表人身份证号码）
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                统一社会信用代码（或其他有效证件代码）为：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>；
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>法定代表人</span>/授权代表人为：<u>&nbsp;&nbsp;&nbsp;</u
                ><u>&nbsp;</u
                ><u>&nbsp;&nbsp;&nbsp;&nbsp;</u
                >，身份证号码为：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                2.&nbsp;合同总价：
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>1）本协议服务单价为100<span>元</span>/ID/学期。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span
                >2）乙方确认向甲方购买200<span>个</span>ID账号，共购买1个学期（一个学期即六个月）服务。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>3）本协议总价为：<b>20000元（大写金额：贰万元整）</b
                ><span>，</span>100 * 200<span>（</span>ID个数）*
                1&nbsp;<span>（学期个数）</span>= 20000元。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                3.&nbsp;付款方式：<u></u>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                乙方在收到中国足球发展基金会资助金后，<u>三个工作日</u>内，向甲方一次性支付本协议总价款。
            </p>
            <p class="16" style="margin-left: 21pt; text-indent: -21pt">
                4.&nbsp;收款信息：
            </p>
            <p class="16" style="margin-left: 21pt">
                &nbsp;<span>开户单位：小牛体育文化发展（重庆）有限公司</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p class="16" style="margin-left: 21pt; text-indent: 5.25pt">
                <span>账号：</span>50050100414100003379
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p class="16" style="margin-left: 21pt; text-indent: 5.25pt">
                开户行：中国建设银行股份有限公司重庆中山路支行
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>三、双方权利与义务</b><b></b>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                1.&nbsp;甲方负责提供服务内容中的课程和教材，并保证所有内容均是正版的、拥有合法权利或合法授权的。保证绝对不会侵犯任何第三方的著作权、商标权、肖像权、姓名权、名誉权、隐私权或者其他合法权益。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                2.&nbsp;甲方将采取合理措施保护乙方合规性操作数据、帐户、密码等信息安全，但如因乙方及其管理人员及第三方原因导致信息丢失或泄露的，与甲方无关。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                3.&nbsp;甲方有权自行决定对服务平台的版本、功能进行变更、升级、优化，有权对服务方式、服务内容进行丰富与优化。乙方同意，甲方无需因上述行为特意通知乙方，并对乙方及乙方管理人员及任何第三人不承担任何责任。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                4.&nbsp;甲方自支付完成起<u>&nbsp;<span>五</span> </u
                >个工作日内为乙方开通相应账号、提供选购服务。如系乙方原因（包括但不限于乙方违约、乙方提供资料或信息存在问题等）导致甲方未能如期为乙方开通账号或提供服务的，由乙方自行承担，甲方服务自本协议到期后自动终止，不予顺延；如系甲方无正当理由未能如期为乙方开通账号并提供服务的，甲方服务相应顺延，服务时间自甲方实际提供服务之日起算。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                5.&nbsp;乙方承诺本协议项下的服务只用于非商业用途。承诺未经甲方书面许可，不对甲方提供的服务及其他自甲方获悉的任何信息或成果，进行复制、出租、出借、出售、或通过其他方式转让、披露于第三人，或通过剪切、编辑、录制及其他方式生成衍生作品。否则，无论是否基于商业目的，均应向甲方承担违约责任，并赔偿甲方一切损失。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                6.&nbsp;<span>乙方不得基于商业目的模仿甲方的产品和</span>/或服务；不得复制或模仿甲方的设计理念、界面、功能和图表；未经甲方许可不得对甲方产品/服务的内容进行修改或制造其他派生产品。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                7.&nbsp;<span>乙方不得利用甲方平台及提供产品</span>/服务进行任何违法或不当的活动，不得在系统平台发表任何违法或不当言论。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                8.&nbsp;<span>甲方仅向乙方提供产品</span>/服务。乙方在组织内部人员学习过程中，应自行对其内部人员、参与人员、管理人员的安全负责，如有安全问题与甲方无关。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                9.&nbsp;<span>乙方按照实名制为其学员购买</span>ID账号及对应服务，每个ID账号仅由实名注册学员单独使用，不可多学员重复使用，否则，乙方应按照实际使用学员人数（即应购买ID个数）向甲方补充相应服务费用，并支付服务费用的30%作为违约金。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                10.&nbsp;乙方如要求开具发票，可自行在<a
                href="http://www.prefootball.cn-my/"
                >“www.prefootball.cn-My </a
                ><span>幼儿园</span>- 费用管理-发票”，向甲方申请开具正规增值税发票。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                11.&nbsp;乙方在成功购买服务后，所支付的服务费用均不作退返。但在下列情况下，甲方同意将乙方订购费用中的剩余款项无息退返：
            </p>
            <p class="MsoNormal" style="margin-left: 64pt; text-indent: -36pt">
                （1）&nbsp;非因乙方原因，甲方无正当理由终止提供服务的；
            </p>
            <p class="MsoNormal" style="margin-left: 64pt; text-indent: -36pt">
                （2）&nbsp;存在其他甲方特别声明退款情形的。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                12.&nbsp;<span>甲方现行免费提供的产品</span>/服务，不应视为甲方放弃收费的权利。甲方随时有权取消免费服务，并收取相应费用。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                13.&nbsp;甲方有权根据实际情况随时单方调整费用标准及收费方式，并公示于系统平台，而无需获得乙方同意。若调整日前乙方已经订购服务并已全额支付费用的，已购买的服务不受影响。
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                14.&nbsp;乙方应按照本协议约定及系统平台用户服务协议、甲方声明及规则、补充协议等规定，在授权范围内享受服务，如乙方违反相关约定的，应向甲方承担违约责任，并赔偿甲方一切损失。如因乙方原因，导致第三方侵害甲方合法权益的，乙方与该第三方向甲方承担连带责任；如因乙方原因导致甲方向第三方进行赔偿的，甲方有权向乙方追偿。所指损失，包括但不限于甲方的直接损失、间接损失、逾期利益损失、为及时止损所支付的合理费用、因乙方侵权或违约行为向第三方所支付的费用，以及为维权所支付调查费、保全费、鉴定费、诉讼费、律师费、差旅费等全部费用。
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>四、违约责任</b><b></b>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                1.&nbsp;<span>甲方应如期为乙方提供服务，逾期未能提供的，按照本协议第三条第</span>4款之约定承担法律责任。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                2.&nbsp;乙方应按照本协议之约定及时履行付款义务，逾期付款的，每逾期一日，按照本协议总价款的<u
                >&nbsp;&nbsp;5 ‰</u
                >向甲方支付违约金；逾期超过三十日的，甲方有权解除本协议，同时要求乙方支付违约金。
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>五、知识产权保护与保密条款</b><b></b>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                1.&nbsp;甲方向乙方所提供的一切内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据、电子文档、软件或应用程序等）的一切著作权、商标权、专利权、商业秘密等知识产权，均归甲方所有，乙方仅在授权范围内享有使用权。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                2.&nbsp;甲方需采取合理的措施保护乙方的信息。除法律法规规定的情形外，未经乙方许可甲方不得向第三方公开、透露用户信息。但为保障服务质量与效率、便于行政管理与监督，乙方同意甲方向预先选定的合作方及教育主管部门提供乙方信息。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                3.&nbsp;乙方应就其自甲方所获取的一切产品、服务及内容予以保密，未经甲方书面许可，无论其是否存在商业目的，均不得以任何形式向第三方予以披露，否则应向甲方承担违约责任，并赔偿甲方一切损失。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                4.&nbsp;各方对在履行协议中获得的商业机密和技术秘密负保密责任。但出现下列任一情况时，甲方有权披露乙方信息：
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>1）
                应人民银行或银监会、证监会、保监会等金融业监管机构要求的；
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>2）
                应公安机关、人民法院、人民检察院、教育部或有权行政机关要求的；
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <span>（</span>3）根据法律规定甲方应当向第三方提供信息的其他情形。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                5.&nbsp;各方保证其本身及其雇员、代理人、管理人员履行本协议保密义务，保密期限为永久。
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>六、争议解决</b><b></b>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                甲乙双方发生任何纠纷或争议，首先应友好协商解决；协商不成的，任何一方有权向甲方所在地有管辖权的人民法院提起诉讼。
            </p>
            <p class="MsoNormal" style="text-indent: 21.1pt">
                <b>七、其他说明</b><b></b>
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                1.&nbsp;本协议未尽事宜，由双方另行协商并签订书面补充协议确定。补充协议系本协议的组成部分，其约定与本协议不一致的，以补充协议为准。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                2.&nbsp;系统平台用户服务协议、甲方声明与使用规则等是本协议的有效组成部分，与本协议具有同等法律效力。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                3.&nbsp;本协议原则上采用线上签订模式，以系统平台上传备案协议内容为准，甲乙双方可自行下载备案协议，下载文本具有同等法律效力。
            </p>
            <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                4.&nbsp;本协议自甲乙双方签字或盖章之日起生效。
            </p>
            <p class="MsoNormal" style="margin-left: 28pt">（以下无正文）</p>
            <p class="MsoNormal"><b>&nbsp;</b></p>
            <p class="MsoNormal"><b>&nbsp;</b></p>
            <p class="MsoNormal" style="margin-left: 28.05pt">
                <b>甲方（盖章）：小牛体育文化发展（重庆）有限公司</b><b></b>
            </p>
            <p class="MsoNormal" style="margin-left: 28.05pt">
                <span>法定代表人</span>/授权代理人（签字）：<u></u>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                >年<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>月<u>&nbsp;&nbsp;&nbsp;</u>日
            </p>
            <p class="MsoNormal">&nbsp;</p>
            <p class="MsoNormal" style="margin-left: 28.05pt">
                <b
                ><span>乙方（盖章）：</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b
                ><b></b>
            </p>
            <p class="MsoNormal" style="margin-left: 28.05pt">
                <span>法定代表人</span>/授权代理人（签字）：<u
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                ><u></u>
            </p>
            <p class="MsoNormal" style="text-indent: 21pt">
                <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
                >年<u>&nbsp;&nbsp;&nbsp;&nbsp;</u>月<u>&nbsp;&nbsp;&nbsp;</u>日
            </p>
            <p class="MsoNormal">&nbsp;</p>
            <!-- 协议结束 -->
            </div>
        </div>
        <!-- <a class="btndis" id="time"></a> -->
        <div class="step-btn">
            <a class="step-btn-next" @click="curStep = 'step3'">返回</a>
        </div>
        </div>
        <!-- 《示范园项目资助协议书》 -->
        <div class="step7" v-show="curStep === 'step32'">
        <div class="title">《示范园项目资助协议书》</div>
        <div class="protocol w1200">
            <div class="box">
            <!-- 协议开始 -->
            <div class="Section0">
                <p class="18" style="text-align: right">合同编号：</p>
                <p class="15">&nbsp;</p>
                <p class="15">&nbsp;</p>
                <p class="15">&nbsp;</p>
                <p class="15" style="text-align: center">
                <img
                    width="605"
                    height="156"
                    src="../../../assets/img/htt.png"
                />&nbsp;
                </p>
                <p class="MsoNormal" align="center" style="text-align: center">
                <b>&nbsp;</b>
                </p>
                <p class="MsoNormal" align="center" style="text-align: center">
                <b>&nbsp;</b>
                </p>
                <p
                class="MsoNormal"
                align="center"
                style="text-align: center; font-size: 0.24rem"
                >
                <b>资助协议书</b>
                <b> </b>
                </p>
                <p class="MsoNormal" align="right" style="text-align: right">
                <br />
                </p>
                <p class="MsoNormal" align="right" style="text-align: right">
                <span>足基青字〔2021〕1号</span>
                </p>
                <p class="MsoNormal" align="right" style="text-align: right">
                <br />
                </p>
                <div class="Section0">
                <p class="MsoNormal">甲方：中国足球发展基金会<b></b></p>
                <p
                    class="MsoNormal"
                    style="margin-left: 42pt; text-indent: -42pt"
                >
                    <span>地址：北京市朝阳区工人体育场东路工人体育场东</span
                    >2门内2号楼
                </p>
                <p class="MsoNormal">法定代表人：王战和</p>
                <p class="MsoNormal">&nbsp;</p>
                <p class="MsoNormal">乙方：</p>
                <p class="MsoNormal">园所编号：</p>
                <p class="MsoNormal">地址：</p>
                <p class="MsoNormal">法定代表人：</p>
                <p class="MsoNormal">&nbsp;</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span
                    >为全面贯彻全国教育大会精神，推进全国幼儿足球科学发展，中国足球发展基金会（以下简称基金会）与全国青少年校园足球工作领导小组办公室（以下简称全国校足办）联合发起</span
                    >“全国足球特色幼儿园示范园（试点）项目”，打造科学的幼儿足球活动样板，整体提升全国幼儿足球教育水平，引导各级各类幼儿园广泛开展幼儿足球活动，促进幼儿身心健康全面发展，培养德智体美劳全面发展的社会主义建设者和接班人。
                </p>
                <p class="15">&nbsp;</p>
                <p class="MsoNormal">
                    &nbsp;&nbsp;&nbsp;&nbsp;<span>一、项目名称</span>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    全国足球特色幼儿园示范园（试点）项目（以下简称示范园项目）。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">二、项目简介</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span
                    >以《全国足球特色幼儿园游戏活动指南》为纲，在全国足球特色幼儿园系统平台（</span
                    >www.prefootball.cn）全面开展幼儿足球教学活动。按照系统平台的科学要求，从幼儿足球的教学、训练、测试、评估、量化积分、教师培训、小小运动会等多方面系统完成教学活动，并在项目期内完整上传园所相关资料及数据。
                </p>
                <p class="MsoNormal" style="text-indent: 20.55pt">
                    三、资助内容和金额
                </p>
                <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                    （一）<b>资助内容</b><b></b>
                </p>
                <p class="MsoNormal">
                    &nbsp;&nbsp;&nbsp;&nbsp;<span>按照基金会给全国校足办的《中国足球发展基金会关于资助全国足球特色幼儿园示范园试点项目的复函》（</span>足基函字<span>〔</span>2021〕19号）要求，结合与全国校足办协商确定的示范园项目内容，本项目资助内容包括但不限于在全国足球特色幼儿园系统平台的以下教学活动：
                </p>
                <p class="MsoNormal" style="text-indent: 27.55pt">
                    <b>1、</b
                    ><b><span>幼儿足球游戏课程体系（</span>LGA）Let's Get Active</b
                    ><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    该<span>课程体系采用</span>“6+1”主题模式，分别是“体操、舞蹈、跑跳能力、身体管理、合作与解决问题、球类运动”+“足球游戏”，覆盖幼儿园小班<span>（</span>3-4岁）、中班<span>（</span>4-5岁）、大班<span>（</span>5-6岁）三个年级，每学期16课时，每周1节课。
                </p>
                <p class="MsoNormal" style="text-indent: 27.55pt">
                    <b>2、</b
                    ><b
                    ><span>幼儿足球游戏测试体系（</span>PDA）Physical Data
                    Archive</b
                    ><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span
                    >该体系以游戏测评形式，对幼儿身体基础指标及运动能力进行测试、分类及报告。</span
                    >PDA测试体系从基础和运动两个层面进行分析，基础数据包括八项指标，包括身高、体重、臂展、胸围、肺活量、视力、坐立体前屈、体脂率；运动测试包括八大维度，包括跑动、跳跃、敏捷、平衡、协调、力量、物体控制（手）、物体控制（脚）。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    3、<b><span>幼儿足球运动评估体系</span>(PES)</b><b>&nbsp;</b
                    ><b>Physical Evaluation System</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    该体系是对幼儿体能发展的数据化体现，将幼儿体质测试数据和运动测试数据进行统计与评估，并生成评估报告反馈给所在幼儿园及家长。通过数据对比，了解和把握幼儿个体的阶段性发展和身心状况，为后期制定幼儿健康活动提供科学依据。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    4、<b>幼儿</b><b>园</b
                    ><b
                    ><span>全国综合量化评估体系</span>(KQA）Kindergarten
                    Quantitative Assessmen</b
                    ><b>t</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    该体系由基础积分、教师积分和幼儿积分三个部分组成，以完整申报幼儿园基础信息管理体系<span>（</span>KMI）信息为基础，以可视化和数据化的形式呈现结果、反馈和改良建议报告，多维度评估幼儿园幼儿足球开展情况。
                </p>
                <p class="MsoNormal" style="text-indent: 27.55pt">
                    <b>5、</b><b>幼儿足球教师</b><b>培训</b
                    ><b><span>体系</span>(TC</b><b>P</b
                    ><b>)Teachers Certification of </b><b>Prefootball</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    该体系主要分为基础普及培训和分级主题培训两大类，主要内容包括：幼儿足球发展政策、理念、方法体系课程；幼儿足球线上教学系统、数据平台实用课程；经典幼儿足球课程讲解及示范教学课程；幼儿情感、心理学基础类课程；运动健康、营养及救护基础课程等。
                </p>
                <p class="MsoNormal" style="text-indent: 27.55pt">
                    <b>6、</b><b><span>小小运动会</span>(KSFA)</b><b>&nbsp;</b
                    ><b>Kids Sports for All</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    在园所及所在地区通过多维运动和游戏活动的趣味组合，形成生动有趣的运动会或嘉年华，体验运动快乐，感受竞争乐趣，培养规则意识及协作精神，激发运动潜能。
                </p>
                <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                    （二）<b>资助金额</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    甲方一次性资助<span>乙方</span>20000元（大写：贰万元整人民币），用于乙方园内200名幼儿（注册数）2020-2021学年度一学期在全国足球特色幼儿园系统平台开展幼儿足球教学活动经费。
                </p>
                <p class="MsoNormal" style="text-indent: 27.4pt">
                    四、双方权利和义务
                </p>
                <p class="MsoNormal" style="text-indent: 28.1pt">
                    <b>（一）甲方权利和义务</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    1.甲方资助乙方的资金为2020-2021年度体育总局向甲方购买服务资金，保证合法有效。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    2.甲方为公益慈善组织，且资助乙方的资金来源为财政部安排的中央专项彩票公益金，有权按照民政部和财政部相关规定,聘请第三方会计师事务所，对该项目进行专项审计，也可要求乙方提供具有法律效力的第三方审计报告。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    3.甲方依据国家有关规定，对本项目实施情况和资助资金使用情况进行监督管理，乙方若未按本协议履约，甲方有权解除本协议并追偿资助资金。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    4.甲方开票信息：
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    单位名称：中国足球发展基金会
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>纳税人识别号：</span>5310 0000 MJ 0065 0654
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>电话</span>:010-67189231
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    5.甲方在本协议生效并收到乙方开具的符合国家规定的票据后，将资助款一次性汇至乙方账户，双方账户信息如下：
                </p>
                <p class="15">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p class="15" style="text-indent: 28pt">1）甲方账户信息：</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    户名：中国足球发展基金会
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>账号：</span>1105 0162 3600 0000 0266
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    开户行：中国建设银行北京前门支行
                </p>
                <p class="15" style="text-indent: 32pt">2）乙方账户信息</p>
                <p class="15" style="text-indent: 32pt">户名：</p>
                <p class="15" style="text-indent: 32pt">账号：</p>
                <p class="15" style="text-indent: 32pt">开户行：</p>
                <p class="MsoNormal" style="text-indent: 28.1pt">
                    <b>（二）乙方权利义务</b><b></b>
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    1.乙方确保按照全国足球特色幼儿园系统平台的要求，保证甲方的资助资金用于该项目，并确保资金使用合法、合规、合理、有效，勤俭节约、杜绝浪费。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    2.乙方需按照全国校足办要求完成示范园项目：
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    ——教育理念：园所需全面贯彻落实全国教育大会精神，牢固树立健康第一的教育理念，帮助幼儿在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。以指南为纲，贯彻校园足球八大体系指导方针，按照“教会、勤练、常赛”的先进理念，开展幼儿足球教学活动。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    ——资格要求：园所需达到在园儿童200人以上规模。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    ——教学要求：园所自愿参加该项目，需按照全国校足办及中国足球发展基金会要求进行申报、签约、教学、数据回馈等相关工作，严格按照全国足球特色幼儿园系统平台所提供的教学体系要求全面开展教学活动，上传相关数据真实有效，不得出现虚假瞒报行为。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    ——资金管理：资助资金应专向用于示范园项目，不得挪作他用。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    ——考核要求：全国校足办将定期对试点园所进行考核，考核标准及园所数据详见全国足球特色幼儿园系统平台。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    3.应在乙方有关本项目的所有活动、宣传和仪式上展示中国足球发展基金会LOGO，并注明“彩票公益金活动—中国体育彩票和中国福利彩票”字样。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    4.乙方须接受国家财政、审计部门和甲方委派社会中介机构进行的专项审计和绩效评价。
                </p>
                <p class="MsoNormal" style="margin-left: 21pt">五、协议终止</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （一）本协议约定事项已完成。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （二）甲乙双方一致决定终止。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （三）政府主管部门决定终止。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （四）乙方未按协议约定使用资助资金或者有其他违反协议情形的，应按照甲方要求限期整改，限期内不能达到要求或严重违反协议规定的，甲方有权解除资助协议，追偿资助资金。
                </p>
                <p class="MsoNormal" style="text-indent: 27.4pt">
                    六、争议解决和适用法律
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （一）甲乙双方在协议履行过程中发生任何争议，应首先友好协商解决。协商不成时，任何一方均可向北京仲裁委员会提起仲裁，通过仲裁程序解决，且仲裁裁决为终局裁决。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （二）本协议的订立、履行和解释及争议的解决均适用中国法律。
                </p>
                <p class="MsoNormal" style="text-indent: 27.4pt">
                    七、<a
                    href="http://zhidao.baidu.com/search?word=%E9%99%84%E5%88%99&amp;fr=qb_search_exp&amp;ie=utf8"
                    >协议修改</a
                    >和补充
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    本协议修改和补充必须由甲乙双方协商一致，以书面补充协议形式确定，经甲乙双方签字、盖章后与本协议具有同等法律效力。
                </p>
                <p class="MsoNormal" style="text-indent: 27.4pt">八、附则</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    本协议以中文签署，自甲乙双方签字、盖章之日起生效；一式两份，甲乙双方各执一份，具有同等法律效力。
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">&nbsp;</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    （以下无正文，为签署栏及附件）
                </p>
                <p class="15">&nbsp;</p>
                <p class="15">&nbsp;</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>甲方：（盖章）</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>乙方</span>:（盖章）
                </p>
                <p class="MsoNormal">&nbsp;</p>
                <p class="15">&nbsp;</p>
                <p class="15">&nbsp;</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>法定代表人或</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    >法定代表人或</span
                    >
                </p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>授权代表：（签字）</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    >授权代表：（签字）</span
                    >
                </p>
                <p class="MsoNormal">&nbsp;</p>
                <p class="MsoNormal" style="text-indent: 28pt">
                    <span>年</span> &nbsp;&nbsp;<span>月</span> &nbsp;&nbsp;<span
                    >日</span
                    >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                    >年</span
                    >
                    &nbsp;&nbsp;<span>月</span> &nbsp;&nbsp;<span>日</span>
                </p>
                </div>
                <br />
                <p class="MsoNormal" style="text-indent: 28pt">
                <b><span>附件</span>:</b><b></b>
                </p>
                <p class="MsoNormal" align="center" style="text-align: center">
                <b>资助项目专项绩效表</b>
                </p>
                <div align="center">
                <table
                    class="MsoNormalTable"
                    border="1"
                    cellspacing="0"
                    style="border: none"
                >
                    <tbody>
                    <tr>
                        <td
                        width="204"
                        valign="center"
                        colspan="3"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >项目名称</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="505"
                        valign="center"
                        colspan="2"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >全国足球特色幼儿园示范园（试点）项目</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="204"
                        valign="center"
                        colspan="3"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >项目资金</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="505"
                        valign="center"
                        colspan="2"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >20000.00</span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            >元（人民币贰万元整）</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="65"
                        valign="center"
                        rowspan="7"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >年</span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            ><br /></span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            >度</span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            ><br /></span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            >总</span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            ><br /></span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            >体</span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            ><br /></span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            >目</span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            ><br /></span
                            ><span style="font-family: 仿宋; font-size: 12pt"
                            >标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="644"
                        valign="center"
                        colspan="4"
                        rowspan="7"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="text-indent: 18pt">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >全面贯彻落实全国教育大会精神，牢固树立健康第一的教育理念，帮助幼儿在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        <p class="MsoNormal" style="text-indent: 18pt">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >以《全国足球特色幼儿园游戏活动指南》为纲，贯彻校园足球八大体系指导方针，在幼儿足球领域贯彻“教会、勤练、常赛”的先进理念，在全国足球特色幼儿园系统平台开展幼儿足球教学活动，详实记录并反馈相应数据。</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr>
                        <td
                        width="65"
                        valign="center"
                        rowspan="11"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >绩效指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="62"
                        valign="center"
                        rowspan="2"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >一级</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="76"
                        valign="center"
                        rowspan="2"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >二级</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="348"
                        valign="center"
                        rowspan="2"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >三级指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        rowspan="2"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >指标值</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td
                        width="62"
                        valign="center"
                        rowspan="6"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >产出指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="76"
                        valign="center"
                        rowspan="6"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >数量指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >幼儿园基础信息管理体系（KMI）</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >录入≥200幼儿</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >幼儿足球游戏课程体系（LGA）</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >≥16节/班</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >幼儿足球游戏测试体系（PDA）</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >≥2次</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >幼儿足球运动评估体系(PES) </span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >≥2次</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >幼儿足球教师培训体系(TCP)</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >教师参与培训</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >小小运动会(KSFA)</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >≥1次</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="62"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >效益指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="76"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >社会效益指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >通过项目的实施可以有效统一思想、明确学校体育包括幼儿园的体育改革发展的总体思路，从而在全国树立标杆，带动全国幼儿体育的整体发展。</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >较显著</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                        width="62"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >满意度指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="76"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            align="center"
                            style="text-align: center"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >服务对象满意度指标</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="348"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p
                            class="MsoNormal"
                            style="text-indent: 24pt; vertical-align: middle"
                        >
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >活动参与人员满意度</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                        <td
                        width="156"
                        valign="center"
                        style="border: 1pt solid windowtext"
                        >
                        <p class="MsoNormal" style="vertical-align: middle">
                            <span style="font-family: 仿宋; font-size: 12pt"
                            >≥85%</span
                            ><span
                            style="font-family: 仿宋; font-size: 12pt"
                            ></span>
                        </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <!-- 协议结束 -->
            </div>
            </div>
        </div>
        <!-- <a class="btndis" id="time"></a> -->
        <div class="step-btn">
            <a class="step-btn-next" @click="curStep = 'step3'">返回</a>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'demonstrationDeclare',
    data(){
        return {
            curStep:'step1',
            schoolInfo:{
                spSchoolName:this.$store.state.schoolInfo.atteSchool.schoolName,
                spContacter:'',
                paddress:'',
                companyName:'',
                idCard:'',
                bankAccount:'',
                bankName:'',
                creditCode:'',
                spEmail:'',
                id: this.$store.state.userInfo.schoolId,
            },
            isWhiteList:true,  //是否在白名单
            certificationList:{
                88:'园所具备签约主体资格,并在系统平台完成实名认证。',
                0:'园所未实名认证,马上认证。',
                1:'实名认证审核中。',
                2:'实名认证失败,重新认证。'
            },
            certificationStatus:0,
            checkStatus:false,
            curInvoiceVal:'普票',  //当前选中的票据序号
            curInvoiceType:1,
            imageUrl:'',
            image:'',
            confirmCheckStatus:false,
            declareStatus:'',   //申报状态
            declareCont:'',
            declareImg:require('@/assets/img/park/Pic_Sbzt_Sbz.png'),
        }
    },
    methods:{
        init(){
            // 查询是否申请过示范园
            this.getScollDta(); // 获取未通过学校信息数据
            this.getUser();
            this.getUserStatus();   //获取示范园申请状态与审核状态
        },
        // 获取未通过学校信息数据
        getScollDta() {
            let id = this.$store.state.userInfo.schoolId;
            this.api.user.schollDataCenter(id).then((res) => {
                if (res.data.confirmStatus == -1) {
                    let schoolInfo = {
                        spSchoolName: res.data.spSchoolName || this.$store.state.schoolInfo.atteSchool.schoolName,
                        companyName: res.data.companyName,
                        bankAccount: res.data.bankAccount,
                        bankName: res.data.bankName,
                        paddress: res.data.paddress,
                        spContacter: res.data.spContacter,
                        idCard: res.data.idCard,
                        id: res.data.id,
                        spEmail: res.data.spEmail,
                        creditCode: res.data.creditCode,
                    };

                    if (res.data.sfyinvoiceURL) {
                        this.flag = true;
                        this.imageUrl = this.constant.URL + "/" + res.data.sfyinvoiceURL;
                        this.image = res.data.sfyinvoiceURL;
                    }
                    this.curInvoiceVal = res.data.sfyinvoiceName,  //当前选中的票据序号
                    this.curInvoiceType = res.data.sfyinvoiceType;
                    this.schoolInfo = schoolInfo;
                    this.checkStatus = true;
                    this.confirmCheckStatus = true;
                }
            });
        },
        getUser() {
            this.api.user.findUserCenter().then((res) => {
                JSON.stringify(localStorage.setItem("yuan", res.data.atteSchool.level));
                if (!res.data.atteSchool.whiteList || res.data.atteSchool.whiteList == 0) {
                    this.isWhiteList = false;
                }
                if (res.data.schoolStatus === "88") {
                    this.certificationStatus = res.data.schoolStatus;
                }
            });
        },
        getUserStatus(){    // 查询示范园申请状态与审核状态
            let data={
                schoolId:parseInt(this.$store.state.userInfo.schoolId),
            }
            this.api.nAdmin.findBehalf(data).then((res) => {
                //level 0:普通 1:团购  2:VIP 3:内部账号 4:示范园 5:公益园  6:第二批示范园
                //declare 0 未申报 1申报成功 2 申报失败
                // if (res.data === 6 || res.data === 1 || res.data == 106) {
                //     this.curStep = 'step4';
                // }

                if(res.data == 6){
                    this.declareStatus = '审核成功',   //申报状态
                    this.declareCont = '您的园所已经通过审核，成为示范园试点园所',
                    this.declareImg = require('@/assets/img/park/Pic_Sbzt_Sucess.png');
                    this.curStep = 'step4';
                }else if(res.data == 106){
                    this.declareStatus = '审核失败',   //申报状态
                    this.declareCont = '您的园所在本次示范园审核中没有通过审核，希望您继续提升您的足球教学工作<br />祝您下次申报成功！',
                    this.declareImg = require('@/assets/img/park/Pic_Sbzt_Fail.png');
                    this.curStep = 'step4';
                }else{
                    this.declareStatus = '申报中',   //申报状态
                    this.declareCont = '您的线上申报已成功，正式进入签约及收付款环节，请您耐心等待系统平台客服老师与您联系<br />如有疑问请拨打客服资讯热线：400-831-9799',
                    this.declareImg = require('@/assets/img/park/Pic_Sbzt_Sbz.png');
                    if(res.data == 1 || res.data == 2){
                        this.curStep = 'step4';
                    }else{
                        this.curStep = 'step1';
                    }
                }
            });
        },
        goCertification(){  //实名认证
            if(this.certificationStatus == 0 || this.certificationStatus == 2){
                this.$router.push({
                    path: "/user",
                });
            }
        },
        startDeclare(){ //开始申报
            if(this.isWhiteList && (this.certificationStatus==88 || this.certificationStatus==1) && this.checkStatus){
                this.curStep = 'step2';
            }
        },
        declarePre(){   //申报填写上一步
            this.curStep = 'step1';
        },
        nextConfirm(){  //申报填写下一步
            if (!this.schoolInfo.spSchoolName)
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.common.validateLength(this.schoolInfo.spSchoolName, 50))
                //开户行
                return this.$notice({
                message: "请输入正确园所名称",
                }).isShow();
            if (!this.schoolInfo.paddress)
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.schoolInfo.spContacter)
                //法定代表人
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.common.textNumberCheck(this.schoolInfo.spContacter, 25))
                //法定代表人
                return this.$notice({
                message: "请输入正确法定代表人姓名",
                }).isShow();
            if (!this.schoolInfo.idCard)
                //法人身份证
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.common.validateIdCard(this.schoolInfo.idCard))
                //法人身份证
                return this.$notice({
                message: "请输入正确法人身份证",
                }).isShow();
            if (!this.schoolInfo.companyName)
                //开户单位名称
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.common.validateLength(this.schoolInfo.companyName, 50))
                //开户单位名称
                return this.$notice({
                message: "请输入正确开户单位名称",
                }).isShow();
            if (!this.schoolInfo.bankName)
                //开户行
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.common.validateLength(this.schoolInfo.bankName, 50))
                //开户行
                return this.$notice({
                message: "请输入正确开户行",
                }).isShow();
            if (!this.schoolInfo.bankAccount)
                //银行账号
                return this.$notice({
                message: "请完善信息后再点下一步",
                }).isShow();
            if (!this.common.validateLength(this.schoolInfo.bankAccount, 25))
                //银行账号
                return this.$notice({
                message: "请输入正确银行账号",
                }).isShow();
            this.schoolInfo.bankAccount = this.schoolInfo.bankAccount.replace(
                /\s|_/g,
                ""
            );
            if (!this.common.validateLength(this.schoolInfo.spEmail, 25)) {
                return this.$notice({
                message: "请输入邮箱",
                }).isShow();
            } else {
                if (!this.common.isEmail(this.schoolInfo.spEmail)) {
                return this.$notice({
                    message: "请输入正确邮箱",
                }).isShow();
                }
            }
            if (!this.schoolInfo.creditCode) {
                return this.$notice({
                message: "请输入统一社会信用代码",
                }).isShow();
            }
            if(!this.image){
                this.$notice({
                    message: "请上传您园所开具过的对应票据",
                }).isShow();
                return;
            }
            let data ={
                ...this.schoolInfo,
                sfyinvoiceName:this.curInvoiceVal,
                sfyinvoiceType:this.curInvoiceType,
                sfyinvoiceURL:this.image,
            };
            this.api.nAdmin.applyExample(data).then((res) => {
                if (!res.code === 20000) {
                    this.$notice({
                        message: this.res.message,
                    }).isShow();
                }else{
                    this.curStep = 'step3';
                }
            });
        },
        handleInvoiceType(num,val){ //切换票据
            this.curInvoiceType = num;
            this.curInvoiceVal = val;
            this.image = '';
            this.imageUrl = '';
        },
        // 上传
        handleAvatarSuccess(res, file) {
            this.image = res.data.filename;
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        confirmDeclare(){   //确认申报
            if(!this.confirmCheckStatus) return;
            var data = {
                schoolId: this.$store.state.userInfo.schoolId,
                role: "2",
            };
            this.api.park.saveContract(data).then((res) => {
                if (!res.code === 20000) {
                    this.$notice({
                        message: this.res.message,
                    }).isShow();
                }else{
                    this.curStep = 'step4';
                }
            });
        },
        confirmPre(){   //确认申报上一步
            this.curStep = 'step2';
        },
    },
    mounted(){
        this.init();
    }
}
</script>
<style scoped lang="scss">
.main{
    padding:0 40px 50px 40px;
    // width:1120px;
    background: #FFFFFF;
    color: #333333;

    .mt40{
        margin-top:40px;
    }
    .title{
        padding: 40px 0;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }
    .step-btn{
        @include flex();
        font-size: 18px;
        .step-btn-pre{
            width: 98px;
            height: 36px;
            border: 1px solid #BA3037;
            border-radius: 6px;
            color: #BA3037;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
        }
        .step-btn-pre:hover{
            background: #FF5E65;
            color: #FFFFFF;
            border: 1px solid #FF5E65;
        }
        .step-btn-next{
            margin-left: 64px;
            width: 112px;
            height: 56px;
            background: url('../../../assets/img/park/Btn_Bg_Next_U.png') no-repeat;
            background-size: cover;
            text-align: center;
            line-height: 56px;
            color: #FFFFFF;
            cursor: pointer;
        }
        .step-btn-next:hover{
            background: url('../../../assets/img/park/Btn_Bg_Next_H.png') no-repeat;
            background-size: cover;
        }
        .unConfirm{
            margin-left: 64px;
            width: 112px;
            height: 56px;
            text-align: center;
            line-height: 56px;
            color: #FFFFFF;
            background: url('../../../assets/img/park/Btn_Bg_S_G.png') no-repeat;
            background-size: cover;
            cursor: not-allowed;
        }
    }
    .declare-check{
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        span {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('../../../assets/img/park/Icon_Xz_Uncho.png') no-repeat;
            background-size:100% 100%;
            margin-right:10px;
        }
        .active{
            background: url('../../../assets/img/park/Icon_Xz_Cho.png') no-repeat;
            background-size:100% 100%;
        }
    }
    .declare-tip{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        color: #BA3037;
    }
    .step1{
        .cont{
            .cont-tit{
                font-size: 20px;
                font-weight: bold;
            }
            .cont-paragraph{
                margin: 20px 0 28px 0;
                font-size: 16px;
                line-height: 24px;
                white-space: normal;
            }
            .cont-demand{
                margin-top: 20px;
                font-size: 18px;
            }
            .cont-undemand{
                font-weight: bold;
                color: #BA3037;
                opacity: 0.9;
                cursor: pointer;
            }
            .noPointer{
                cursor: default;
            }
        }
        .start-declare{
            margin-top:40px;
            text-align: center;

            .start-declare-check{
                font-size: 16px;
            }
            .start-declare-check{
                font-size: 18px;
                text-align: center;
                span {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background: url('../../../assets/img/park/Icon_Xz_Uncho.png') no-repeat;
                    background-size:cover;
                    margin-right:10px;
                }
                .active{
                    background: url('../../../assets/img/park/Icon_Xz_Cho.png') no-repeat;
                    background-size:cover;
                }
            }
            .start-declare-btn{
                margin:16px auto 0 auto;
                width:232px;
                height:56px;
                text-align: center;
                line-height:56px;
                background: url('../../../assets/img/park/Btn_Bg_Sb_U.png') no-repeat;
                background-size:cover;
                color:#FFFFFF;
                font-size: 18px;
                cursor:pointer;
            }
            .undeclare{
                margin:16px auto 0 auto;
                width:232px;
                height:56px;
                text-align: center;
                line-height:56px;
                background: url('../../../assets/img/park/Btn_Bg_Sb_Gray.png') no-repeat;
                background-size:cover;
                color:#FFFFFF;
                font-size: 18px;
                cursor: not-allowed;
            }
            .start-declare-btn:hover{
                background: url('../../../assets/img/park/Btn_Bg_Sb_H.png') no-repeat;
                background-size:cover;
            }
        }
    }
    .step2{
        color: #333333;
        .form-tit{
            font-size: 20px;
            font-weight: bold;
        }
        .mt30{
            margin-top: 30px;
        }
        .form-row{
            margin-top: 20px;

            .form-item{
                font-size: 16px;
                .form-item-tit{
                    font-size: 16px;
                    @include flex('row','flex-start','center');
                    > span{
                        padding-top:3px;
                        padding-right:10px;
                        color: #FF0000;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }
            .form-item-inp{
                margin-top: 6px;
            }
            .form-item-tips{
                margin-top: 10px;
                font-size: 14px;
                color: #BA3037;
            }
        }
        .invoice-list{
            margin-top: 30px;

            .invoice-item{
                padding-left: 14px;
                height: 40px;
                line-height: 40px;
                border: 1px solid #40528F;
                border-radius: 19px;
                font-size: 16px;
                cursor:pointer;
            }
            .active{
                background: #40528F url('../../../assets/img/park/Icon_Lx_choose 2.png') no-repeat 387px center;
                background-size: 28px 28px;
                color: #FFFFFF;
            }
        }
        .reminder{
            margin-top: 36px;
            margin-bottom: 10px;
            font-size: 16px;
            color: #333333;
            @include flex('row','flex-start','center');
            > span{
                padding-top:3px;
                padding-right:10px;
                color: #FF0000;
                font-size: 18px;
                font-weight: 400;
            }
        }
        .avatar-uploader-icon {
            border: 1px dashed #DDDDDD;
            border-radius: 8px;
            font-size: 28px;
            color: #8c939d;
            width: 122px;
            height: 142px;
            line-height: 142px;
            text-align: center;
            margin-bottom: 40px;
        }
        .avatar {
            width: 122px;
            height: 142px;
            display: block;
        }

    }
    .step3{
        .doc-file{
            margin-bottom: 50px;
            @include flex();
            .doc-file-l{
                margin-right: 130px;
            }
            .doc-file-l,
            .doc-file-r{
                padding:40px 0 0 36px;
                background: url('../../../assets/img/park/Btn_Bg_SbXy_U.png') no-repeat;
                background-size: cover;
                width:364px;
                height: 80px;
                font-size: 24px;
                font-weight: bold;
                color: #FFFFFF;
                cursor:pointer;
            }
            .doc-file-l:hover,
            .doc-file-r:hover{
                background: url('../../../assets/img/park/Btn_Bg_SbXy_H.png') no-repeat;
                background-size: cover;
            }
        }
    }
    .step4-cont{
        text-align: center;
        .step4-cont-img{
            width:124px;
            height:124px;
        }
        .step4-cont-status{
            margin-top: 15px;
            margin-bottom: 30px;
            font-size:18px;
        }
        .step4-cont-tip{
            font-size: 16px;
            line-height: 28px;
        }
    }
    .protocol {
        background-color: rgb(250, 250, 250);
        padding: 0.2rem;
    }

    .protocol .box {
        border-width: 1px;
        border-color: rgb(250, 205, 137);
        border-style: solid;
        padding: 0.5rem;
        color: #505051;
        /* height: 4rem; */
        box-sizing: border-box;
        border-radius: 0.1rem;
        line-height: 0.4rem;
        background-color: #fff;
    }

    .protocol .box .title {
        font-size: 0.32rem;
        line-height: 0.32rem;
        text-align: center;
        margin-bottom: 0.3rem;
    }

    .protocol .box .content {
        font-size: 0.26rem;
        line-height: 0.4rem;
        /* height: 2.8rem;
        box-sizing: border-box;
        overflow: scroll; */
    }
    .MsoNormal{
        white-space: normal;
    }
    .btnsub {
        display: block;
        border: 0;
        border-radius: 0.1rem;
        background-color: rgb(204, 26, 48);
        width: 4rem;
        height: 0.7rem;
        line-height: 0.7rem;
        margin: 0.5rem auto 1rem auto;
        font-size: 0.3rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
    }

    .btnsub:hover {
        color: #fff;
        opacity: 0.7;
    }
}
</style>